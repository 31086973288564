// extracted by mini-css-extract-plugin
export var stickerPage = "StickerPage-module--stickerPage--8-iB-";
export var content = "StickerPage-module--content--14rUM";
export var titleContainer = "StickerPage-module--titleContainer--1ajLZ";
export var title = "StickerPage-module--title--QRcgD";
export var titleDescriptionContainer = "StickerPage-module--titleDescriptionContainer--29gES";
export var titleDescription = "StickerPage-module--titleDescription--1-BJX";
export var offerContainerWrapper = "StickerPage-module--offerContainerWrapper--hdWIo";
export var offerContainer = "StickerPage-module--offerContainer--P9YIq";
export var offerTitle = "StickerPage-module--offerTitle--nYxia";
export var offerList = "StickerPage-module--offerList--3QJLz";
export var offerListItem = "StickerPage-module--offerListItem--1IK7Z";
export var imageContainer = "StickerPage-module--imageContainer--2njG3";
export var offerDescription = "StickerPage-module--offerDescription--3rDQx";
export var offerDescriptionText = "StickerPage-module--offerDescriptionText--qWANJ";
export var button = "StickerPage-module--button--3rx9P";
export var lastOffer = "StickerPage-module--lastOffer--2fWTW";
export var agreeContainer = "StickerPage-module--agreeContainer--10OI1";
export var agree = "StickerPage-module--agree--YSPio";
export var declineOffer = "StickerPage-module--declineOffer--2AvAl";
export var declineOfferLink = "StickerPage-module--declineOfferLink--1uN1d";
export var footerContainer = "StickerPage-module--footerContainer--2Awox";