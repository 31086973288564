import React, { useState, useEffect } from 'react'
import { Link, graphql } from "gatsby";
import ProgressiveImage from 'react-progressive-image'
import { trackingGA } from '@merchx-v2/helpers'
import { connect } from 'react-redux'
import { useSiteMetadata } from 'hooks/use-site-metadata'
import { navigate } from 'utils/navigate'
import { Button, Footer, Header, Notification, PreventDirectLink, FacebookPixels, SEO } from 'components'
import { selectors } from 'state/StickerPage/reducer'
import { actionCreators as actionHomeCreators, selectMenu, selectCartId } from 'state/HomePage/reducer'

import * as actions from 'state/StickerPage/actions'
import * as styles from './StickerPage.module.scss'

const StickerPage = ({ cartId, addUpsellToOrder, stickerState, menu, resetMenuFilters, setMenuCollection, data }) => {
  // history.listen(listener => {
  //   if (listener.pathname.match(/(product)|(shipping)|(billing)|(upsell)|(sticker)|(upgrade)/)) {
  //     history.goForward()
  //   }
  // })

  const { shared } = useSiteMetadata()

  // Lets define next page route
  let nextPagePath = '/thankyou'

  if (shared.HAS_UPGRADE_PAGE) {
    nextPagePath = '/upgrade'
  }

  const stickerName1 = data.markdownRemark?.frontmatter?.STICKER_1_NAME
  const stickerName2 = data.markdownRemark?.frontmatter?.STICKER_2_NAME
  const stickerName3 = data.markdownRemark?.frontmatter?.STICKER_3_NAME

  const [notification, showNotification] = useState()

  useEffect(() => {
    trackingGA()
  }, [])

  useEffect(() => {
    showNotification(stickerState.UIState.error)
  }, [stickerState.UIState.error])

  const handleConfirmUpsellClicked = async () => {
    const paid = await addUpsellToOrder()
    if (paid.payload?.isSaved) {
      navigate(nextPagePath)
    }
  }

  const handleCollectionClicked = collection => {
    setMenuCollection(collection)
  }

  const handleReset = () => {
    resetMenuFilters()
  }

  return (
    <div className={styles.stickerPage}>
      <PreventDirectLink cartId={cartId} />
      <SEO pathname='/sticker' />
      <FacebookPixels id={shared.FACEBOOK_PIXEL_ID} event='ViewContent' />
      <div className={styles.content}>
        <Header menu={menu} onCollectionClicked={handleCollectionClicked} onResetCollection={handleReset} />
        <div className={styles.titleContainer}>
          <p className={styles.title}>Limited Edition Sticker Pack, 80% OFF!</p>
          <div className={styles.titleDescriptionContainer}>
            <p className={styles.titleDescription}>This is an insane deal you can't find anywhere else. Our hottest selling sticker pack is 80% Off for a limited time.</p>
          </div>
        </div>

        <div className={styles.offerContainerWrapper}>
          <div className={styles.offerContainer}>
            <div className={styles.offerTitle}>
              <p>3 Sticker Pack show off your pride!</p>
            </div>
            <div className={styles.offerList}>
              <div className={styles.offerListItem}>{stickerName1} (Top Seller!)</div>
              <div className={styles.offerListItem}>{stickerName2} (Limited Edition!)</div>
              <div className={styles.offerListItem}>{stickerName3} (Classic!)</div>
            </div>
          </div>
        </div>

        <div className={styles.imageContainer}>
          <ProgressiveImage src={stickerState?.stickerPrice?.imageUrl} placeholder={stickerState?.stickerPrice?.previewImageUrl}>
            {src => <img src={src} alt='' />}
          </ProgressiveImage>
          {/* <img src={stickerState?.stickerPrice?.imageUrl} alt="" /> */}
        </div>

        <div className={styles.offerDescription}>
          <p className={styles.offerDescriptionText}>Place them on your car bumper, your laptop, anywhere you want to show your pride.</p>
        </div>

        <div className={styles.button}>
          <Button value='Yes, Please add this to my order.' path='/thankyou' customAction={handleConfirmUpsellClicked} loading={stickerState.UIState.isLoading} />
        </div>

        <div>
          <p className={styles.lastOffer}>One time offer, last chance!</p>
        </div>

        <div className={styles.agreeContainer}>
          <div className={styles.agree}>
            By pressing 'Claim my offer', you agree to claim the Limited Edition Sticker Pack for 80% off and be charged $
            {((stickerState?.stickerPrice?.price || 0) / 100).toFixed(2)} on top of your existing order.
          </div>
        </div>

        <div className={styles.declineOffer}>
          <Link to={nextPagePath} className={styles.declineOfferLink}>
            No, thanks. I decline this offer
          </Link>
        </div>
      </div>
      <div className={styles.footerContainer}>
        <Footer handleReset={handleReset} />
        <Notification message={notification} />
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  cartId: selectCartId(state),
  stickerState: selectors.selectStickerState(state),
  menu: selectMenu(state)
})

const mapDispatchToProps = {
  addUpsellToOrder: actions.addUpsellToOrder,
  resetMenuFilters: actionHomeCreators.resetMenuFilters,
  setMenuCollection: actionHomeCreators.setMenuCollection
}

export default connect(mapStateToProps, mapDispatchToProps)(StickerPage)

export const query = graphql`
  query StickerPage {
    markdownRemark(fields: {slug: {eq: "/sticker/"}}) {
      frontmatter {
        STICKER_1_NAME
        STICKER_2_NAME
        STICKER_3_NAME
      }
    }
  }
`
